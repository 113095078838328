import React from 'react'

interface IProps {
  fullWidth?: boolean
  className?: string
}

const Divider: React.FC<IProps> = ({ fullWidth, className }) => (
  <div className={fullWidth ? '' : 'container'}>
    <hr className={className} />
  </div>
)

export default Divider
