import React from 'react'
import { Container, Row } from 'reactstrap'

// IMAGES:
import boligPartner from 'Images/clients/clientlogos/client-bolig@2x.png'
import trebetong from 'Images/clients/clientlogos/client-trebetong.png'
import fronta from 'Images/clients/clientlogos/client-fronta.png'
import norskeHus from 'Images/clients/clientlogos/client-norskehus@2x.png'
import nebbenes from 'Images/clients/clientlogos/client-nebbenes-2.jpg'
import hedalm from 'Images/clients/clientlogos/client-ha@2x.png'
import haubo from 'Images/clients/clientlogos/haubo.png'
import drommekJokke from 'Images/clients/clientlogos/client-drommekjokke.png'
import hurre from 'Images/clients/clientlogos/client-huurre@2x.png'
import oceanBathrooms from 'Images/clients/clientlogos/ocean-bathrooms.png'
import roscommonCoCo from 'Images/clients/clientlogos/RoscommonCoCo.png'
import dapatchi from 'Images/clients/clientlogos/dapatchi-group.png'
import brinkman from 'Images/clients/clientlogos/brinkman.svg'
import roche from 'Images/clients/clientlogos/roche.svg'
import waterproof from 'Images/clients/clientlogos/waterproof.png'
import bloydStreet from 'Images/clients/clientlogos/bloyd-street.png'
import rockyMountain from 'Images/clients/clientlogos/rocky-mountain.jpg'
import lngBuilders from 'Images/clients/clientlogos/lng-builders.png'
import broadwing from 'Images/clients/clientlogos/broadwing.png'
import gunn from 'Images/clients/clientlogos/gunn.png'
import leufroy from 'Images/clients/clientlogos/leufroy.png'
import Zmarthaus from 'Images/clients/clientlogos/Zmarthaus.png'
import Ferrata from 'Images/clients/clientlogos/Ferrata.png'
import flamingo from 'Images/clients/clientlogos/flamingo.png'
import Copperstone from 'Images/clients/clientlogos/Copperstone.png'

interface IProps {
  headerId: string
  usaId: string
  ukId: string
  noId: string
  moreId: string
  className?: string
}

const Clients: React.FC<IProps> = ({
  headerId,
  usaId,
  ukId,
  noId,
  moreId,
  className,
}) => {
  const clientsUsa = [
    brinkman,
    roche,
    bloydStreet,
    waterproof,
    rockyMountain,
    lngBuilders,
  ]

  const clientsUk = [
    dapatchi,
    broadwing,
    roscommonCoCo,
    oceanBathrooms,
    gunn,
    leufroy,
    Zmarthaus,
    Ferrata,
    flamingo,
    Copperstone,
  ]

  const clientsNo = [
    boligPartner,
    hedalm,
    fronta,
    trebetong,
    norskeHus,
    drommekJokke,
    hurre,
    haubo,
    nebbenes,
  ]

  return (
    <section className={className ? `${className}` : 'py-8'}>
      <Container className="text-center">
        <h2 className="lead">{headerId}</h2>

        <Row className="d-flex flex-column mt-5">
          <h2 className="lead text-left">{usaId}</h2>
          <div>
            {clientsUsa.map((src, index) => (
              <img
                className="m-3"
                alt="Fonn client logo"
                style={{ maxHeight: '3.5rem', maxWidth: '12rem' }}
                src={src}
                key={index}
              />
            ))}
          </div>
          <h4 className="text-left pt-2 pb-5" style={{ fontWeight: 400 }}>
            {moreId}
          </h4>
        </Row>

        <Row className="d-flex flex-column mt-5">
          <h2 className="lead text-left">{ukId}</h2>
          <div>
            {clientsUk.map((src, index) => (
              <img
                className="m-3"
                alt="Fonn client logo"
                style={{ maxHeight: '3.5rem', maxWidth: '12rem' }}
                src={src}
                key={index}
              />
            ))}
          </div>
          <h4 className="text-left pt-2 pb-5" style={{ fontWeight: 400 }}>
            {moreId}
          </h4>
        </Row>

        <Row className="d-flex flex-column mt-5">
          <h2 className="lead text-left">{noId}</h2>
          <div>
            {clientsNo.map((src, index) => (
              <img
                className="m-3"
                alt="Fonn client logo"
                style={{ maxHeight: '3.5rem', maxWidth: '12rem' }}
                src={src}
                key={index}
              />
            ))}
          </div>
          <h4 className="text-left pt-2 pb-5" style={{ fontWeight: 400 }}>
            {moreId}
          </h4>
        </Row>
      </Container>
    </section>
  )
}

export default Clients
